import Button from "../../components/Button/Button";
import Section from "../../components/Section/Section";
import info from "../../data/Info";
import useScroll from "../../hooks/useScroll";
import "./Blogs.css";
import { FaBloggerB } from "react-icons/fa";

const Blogs = () => {
  useScroll("hw__blogs", "animate__fadeInUp");

  return (
    <Section
      title="blogs"
      width="1000px"
      desc="From life lessons to tech skills, dive into my world of thoughts and experiences."
    >
      <div className="hw__blogs">
        {info.blogs.map((blog) => (
          <a
            key={blog.title}
            className="hw__blogs-card"
            style={{ background: blog.bgColor, color: blog.textColor }}
            href={blog.link}
            target="_blank"
            rel="noreferrer"
            title={blog.title}
          >
            <img src={blog.img} alt={blog.title} />

            <span
              style={{
                background: `linear-gradient(to bottom, transparent, ${blog.bgColor} 80%)`,
              }}
            >
              {blog.title}
            </span>
          </a>
        ))}
      </div>

      <Button
        link="https://blog.harriswong.top/"
        size="large"
        icon={<FaBloggerB />}
        name="Find Out More"
        className="hw__blogs-button"
      />
    </Section>
  );
};

export default Blogs;
