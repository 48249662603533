import { createContext, useState } from "react";

const SideBarContext = createContext();

const SideBarContextProvider = ({ children }) => {
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const [current, setCurrent] = useState("");

  return (
    <SideBarContext.Provider
      value={{ isSideBarOpen, setIsSideBarOpen, current, setCurrent }}
    >
      {children}
    </SideBarContext.Provider>
  );
};

export { SideBarContextProvider, SideBarContext };
