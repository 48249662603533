import { useEffect } from "react";
import Section from "../../components/Section/Section";
import info from "../../data/Info";
import useScroll from "../../hooks/useScroll";
import "./Testimonials.css";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa6";

const Testimonials = () => {
  useScroll("hw__testimonials", "animate__fadeInUp");

  useEffect(() => {
    // Ensure Waterfall.js is loaded before using it
    const script = document.createElement("script");
    script.src =
      "https://cdnjs.cloudflare.com/ajax/libs/waterfall.js/1.0.2/waterfall.min.js";
    script.onload = () => {
      // Ensure the function is available before calling it
      if (window.waterfall) {
        window.waterfall(".hw__testimonials");
      }
    };
    document.body.appendChild(script);

    // Clean up script after component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Section
      title="testimonials"
      width="1000px"
      desc="Curious about what it's like to work with me? Let my colleagues do the talking!"
    >
      <div className="hw__testimonials">
        {info.testimonials.map((item) => (
          <div
            className="hw__testimonials-item"
            key={item.name}
            style={{
              background: `linear-gradient(to bottom, ${item.themeColor} 0, transparent 90px)`,
              borderColor: `${item.themeColor}`,
            }}
          >
            <div className="hw__testimonials-item-row">
              <div className="hw__testimonials-item-img">
                {item.img && (
                  <img src={item.img} alt={`${item.name} profile`} />
                )}
              </div>

              <div className="hw__testimonials-item-col">
                <span className="hw__testimonials-item-name">{item.name}</span>
                <span className="hw__testimonials-item-title">
                  {item.title}
                </span>
              </div>
            </div>

            <p>
              <FaQuoteLeft />
              {item.comment}
              <FaQuoteRight />
            </p>
          </div>
        ))}
      </div>
    </Section>
  );
};

export default Testimonials;
