import React from "react";
import "./Button.css";

// Default: contained, medium
const Button = ({
  name,
  tooltip,
  icon,
  link,
  variant,
  size,
  onClick,
  className,
  children,
}) => (
  <>
    {link ? (
      <a
        href={link}
        className={`hw__button${
          variant === "outlined"
            ? " hw__button-outlined"
            : " hw__button-contained"
        }${size === "large" ? " hw__button-large" : " hw__button-medium"}${
          icon && !name ? " hw__button-icon-only" : ""
        } ${className}`}
        title={tooltip}
        target="_blank"
        rel="noreferrer"
      >
        {icon}
        {name && <span>{name}</span>}
      </a>
    ) : (
      <button
        className={`hw__button${
          variant === "outlined"
            ? " hw__button-outlined"
            : " hw__button-contained"
        }${size === "large" ? " hw__button-large" : " hw__button-medium"}${
          name ? "" : " hw__button-notext"
        } ${className}`}
        onClick={onClick}
      >
        {children}
      </button>
    )}
  </>
);

export default Button;
