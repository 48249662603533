import React from "react";
import "./App.css";
import BackToTop from "./components/BackToTop/BackToTop";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import SideBar from "./components/SideBar/SideBar";
import Wave from "./components/Wave/Wave";
import About from "./container/About/About";
import Contact from "./container/Contact/Contact";
import Education from "./container/Education/Education";
import Projects from "./container/Projects/Projects";
import Skills from "./container/Skills/Skills";
import ASCIIArt from "./utils/ASCIIArt";
import { ReactLenis } from "@studio-freight/react-lenis";
import Work from "./container/Work/Work";
import Testimonials from "./container/Testimonials/Testimonials";
import Certifications from "./container/Certifications/Certifications";
import Blogs from "./container/Blogs/Blogs";

function App() {
  const hour = new Date().getHours();

  const [isDark, setIsDark] = React.useState(hour < 6 || hour >= 18);

  const changeTheme = () => {
    setIsDark((prev) => !prev);
  };

  return (
    <div className={`App${isDark ? " dark" : ""}`}>
      <Navbar changeTheme={changeTheme} />

      <ReactLenis
        root
        options={{
          easing: (t) => 1 - (1 - t) ** 2,
        }}
      >
        <SideBar />
        <About />
        <Skills />
        <Education />
        <Work />
        <Wave isDark={isDark}>
          <Projects />
        </Wave>
        <Blogs />
        <Certifications />
        <Testimonials />
        <Contact />
        <Footer />
      </ReactLenis>

      <BackToTop />
      <ASCIIArt />
    </div>
  );
}

export default App;
