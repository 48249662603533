import "./Certifications.css";
import Section from "../../components/Section/Section";
import useScroll from "../../hooks/useScroll";
import { PiCertificateFill } from "react-icons/pi";
import info from "../../data/Info";
import { useCallback, useEffect, useState } from "react";
import Button from "../../components/Button/Button";

const data = info.certifications;
const tagsMap = data.reduce((acc, crt) => {
  crt.tags.forEach((tag) => {
    acc.set(tag, (acc.get(tag) || 0) + 1);
  });
  return acc;
}, new Map());
const tags = [
  ["All", data.length],
  ...Array.from(tagsMap).toSorted((a, b) => b[1] - a[1]),
];

const Certifications = () => {
  const [cerData, setCerData] = useState(data);
  const [current, setCurrent] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [selected, setSelected] = useState("All");

  useScroll("hw__certifications", "animate__fadeInUp");

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isHovered) {
        setCurrent((prev) => (prev < cerData.length - 1 ? prev + 1 : 0));
      }
    }, 3500);

    return () => {
      clearInterval(interval);
    };
  }, [isHovered, cerData.length]);

  const onSelectTag = useCallback((tag) => {
    setSelected(tag);
    setCurrent(0);

    setCerData(() => {
      if ("All" === tag) return data;
      return data.filter((item) => item.tags.includes(tag));
    });
  }, []);

  const Tabs = useCallback(
    () => (
      <>
        {tags.map(([tag, tagCount], index) => (
          <Button
            onClick={() => onSelectTag(tag)}
            className={
              selected === tag ? "hw__certifications-tab-selected" : ""
            }
            variant="outlined"
            key={`certification-tab-${index + 1}`}
          >
            <div>
              {tag}

              <span className="hw__certifications-tab-sup">{tagCount}</span>
            </div>
          </Button>
        ))}
      </>
    ),
    [selected, onSelectTag]
  );

  const CertificationItems = useCallback(
    () => (
      <>
        <div className="hw__certifications-list">
          <div className="hw__certifications-header">
            <PiCertificateFill />
            <span>Certificate List</span>
          </div>

          <div className="hw__certifications-list-scroll lenis">
            <ul>
              {cerData.map((item, index) => (
                <li
                  key={`list-${item.name}`}
                  className={current === index ? "current" : ""}
                  onClick={() => setCurrent(index)}
                  title={item.name}
                >
                  {item.name}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="hw__certifications-content">
          <div className="hw__certifications-imgs">
            {cerData.map((item, index) => (
              <img
                key={`img-${item.name}`}
                className={current === index ? "current" : ""}
                src={item.img}
                alt={item.name}
                title={item.name}
              />
            ))}
          </div>
          <div className="hw__certifications-tags">
            {cerData[current].tags.map((tag) => (
              <span key={tag}>{tag}</span>
            ))}
          </div>
        </div>
      </>
    ),
    [current, cerData]
  );

  return (
    <Section
      title="certifications"
      desc="Certified and ready to elevate your next big idea with expertise."
    >
      <div className="hw__certifications-tabs lenis">{Tabs()}</div>

      <div
        className="hw__certifications"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {CertificationItems()}
      </div>
    </Section>
  );
};

export default Certifications;
