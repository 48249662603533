import React, { useEffect } from "react";
import "./Navbar.css";
import { VscColorMode } from "react-icons/vsc";
import { FiMenu } from "react-icons/fi";
import { SideBarContext } from "../../contexts/SideBarContext";
import favicon from "../../assets/favicon.svg";
import info from "../../data/Info";
import { useLenis } from "@studio-freight/react-lenis";

const Navbar = ({ changeTheme }) => {
  const { setIsSideBarOpen, current, setCurrent } =
    React.useContext(SideBarContext);

  const lenis = useLenis();

  const MenuItems = () => (
    <ul>
      {info.sections.map((item, index) => (
        <li key={`menu-${index + 1}`}>
          <a
            className={current === item ? "current" : ""}
            href={`#hw__${item}`}
            onClick={() =>
              lenis.scrollTo(`#hw__${item}`, {
                offset: -66,
                easing: (t) => 1 - (1 - t) ** 2,
              })
            }
          >
            {item}
          </a>
        </li>
      ))}
    </ul>
  );

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight / 2;

      // 遍历各个section，找到距离视图中间最近的那个
      info.sections.forEach((section) => {
        const element = document.getElementById(`hw__${section}`);
        if (element) {
          const sectionTop = element.offsetTop;
          const sectionBottom = sectionTop + element.offsetHeight;

          if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
            setCurrent(section);
          }
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [setCurrent]);

  return (
    <nav className="hw__navbar">
      <div className="hw__navbar-logo">
        <img src={favicon} alt="favicon" className="hw__navbar-logo-img" />
        <p
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          className="hw__font-georgia"
        >
          Harris's Portfolio
        </p>
      </div>

      <div className="hw__navbar-menu">
        <MenuItems />
      </div>

      <div className="hw__navbar-func">
        <div className="hw__navbar-theme">
          <VscColorMode onClick={changeTheme} />
        </div>
        <div
          className="hw__navbar-menu-mb"
          onClick={() => setIsSideBarOpen(true)}
        >
          <FiMenu />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
