import React from "react";
import "./SideBar.css";
import { ImCross } from "react-icons/im";
import { SideBarContext } from "../../contexts/SideBarContext";
import info from "../../data/Info";
import { useLenis } from "@studio-freight/react-lenis";

const SideBar = () => {
  const { isSideBarOpen, setIsSideBarOpen, current } =
    React.useContext(SideBarContext);

  const lenis = useLenis();

  const onScrollTo = (item) => {
    lenis.scrollTo(`#hw__${item}`, {
      offset: -66,
      easing: (t) => 1 - (1 - t) ** 2,
    });
    setTimeout(() => setIsSideBarOpen(false), 400);
  };

  return (
    <div className={`hw__sidebar${isSideBarOpen ? " hw__sidebar-show" : ""}`}>
      <div className="hw__cross" onClick={() => setIsSideBarOpen(false)}>
        <ImCross />
      </div>

      <ul>
        {info.sections.map((item, index) => (
          <li key={`menu-${index + 1}`}>
            <a
              className={`hw__font-georgia ${
                current === item ? "current" : ""
              }`}
              href={`#hw__${item}`}
              onClick={() => onScrollTo(item)}
            >
              {item}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SideBar;
