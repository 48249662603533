import React from "react";
import "./Timeline.css";

const Timeline = ({ data }) => {
  return (
    <div id="hw__timeline">
      {data.map((item) => (
        <div className="hw__timeline-item" key={`timeline-${item.institution}`}>
          <div className="hw__timeline-item-left">
            <div className="hw__timeline-logo">
              <img
                src={item.logo}
                alt="Organisation logo"
                style={item.logoStyle}
              />
            </div>
            <div className="hw__timeline-line" />
          </div>

          <div className="hw__timeline-item-right">
            <div className="hw__timeline-tags">
              {item.tags.map((tag) => (
                <span key={tag}>{tag}</span>
              ))}
            </div>
            <div className="hw__timeline-title">
              {item.jobTitle && item.jobTitle + " "}
              <a className="hw__link-text" href={item.link}>
                {item.jobTitle && "@"} {item.institution}
              </a>
            </div>
            <div className="hw__timeline-subtitle">{item.subtitle}</div>

            {item.desc && (
              <ul className="hw__timeline-desc">
                {item.desc.map((desc) => (
                  <li key={desc}>{desc}</li>
                ))}
              </ul>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Timeline;
